@font-face {
  font-family: "Helvetica Monospaced";
  src: url("./assets//helvetica-monospaced-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Stage {
  text-align: center;
  padding: 5rem;
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor: default;
  user-select: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.Word {
  margin-right: 0.33ch;
}

.Word:last-of-type {
  margin-right: 0;
}

.Controls {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Helvetica Monospaced";
  letter-spacing: 0.05em;
  max-height: 25vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.Controls__toggle {
  display: block;
  width: 100%;
  min-height: 1rem;
}

.Control {
  text-align: center;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem;
}

.Control__label {
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.5em;
}

.Control__fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Control__sub {
  margin: 0 1rem;
}
